import { all, fork } from 'redux-saga/effects';

// public
import LayoutSaga from './layout/saga';
// import calendarSaga from './calendar/saga';
// import chatSaga from './chat/saga';
// import ecommerceSaga from './e-commerce/saga';
// import invoiceSaga from './invoices/saga';
// import contactsSaga from './contacts/saga';

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    // fork(calendarSaga),
    // fork(chatSaga),
    // fork(ecommerceSaga),
    // fork(invoiceSaga),
    // fork(contactsSaga),
  ]);
}
