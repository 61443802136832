import React, { useState, useEffect } from 'react';  

const CountdownTimer = ({ minutes=0, hours=0, seconds=0 , onComplete=() => null }) => {
  const initialTime = (hours * 60 * 60) + (minutes * 60) + seconds;
  const [timeRemaining, setTimeRemaining] = useState(initialTime);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          // Perform actions when the timer reaches zero
          onComplete();
          return 0;
        } else {
          return prevTime - 1;
        }
      });
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerInterval);
  }, []); // The empty dependency array ensures the effect runs only once on mount

  // Convert seconds to hours, minutes, and seconds
  const h = Math.floor(timeRemaining / 3600);
  const m = Math.floor((timeRemaining % 3600) / 60);
  const s = timeRemaining % 60;

  return (
      <span>{` ${h ? `${h}h` : ''} ${m ? `${m}m` : ''} ${s}s`}</span>
  );
};

export default CountdownTimer;