import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import {
    ChevronLeftOutlined,
    ChevronRightOutlined,
    CloseOutlined,
} from '@mui/icons-material';
import { Modal, Box } from '@mui/material';
import { getS3BaseUrl } from "../../helpers/string_helper";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Adjusted width for responsiveness
    maxWidth: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px'
};


export function FoodMenuDocumentSlider({ files, isOpen, handleClose }) {
    const sliderRef = useRef(null);
    const s3BaseUrl = getS3BaseUrl();
    const [documents, setDocuments] = useState();

    const goToPrevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const goToNextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    // useEffect(() => {
    //     if (files?.length > 0) {
    //         const result = files?.map((item) => {
    //             return { ...item, location: `${s3BaseUrl}${item?.location}` }
    //         })
    //         setDocuments(result)
    //     }
    // }, [files]);

    return (

        <div>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="tenantRemove resp_tenant_modal">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="crossIcon cursor-pointer position-absolute">
                            <CloseOutlined onClick={handleClose} />
                        </div>
                    </div>
                    <div className="amenityImagesSlider">
                        {files?.length > 0 ? (
                            <div>
                                <div className="slick-arrow custom-prev" onClick={goToPrevSlide}>
                                    <ChevronLeftOutlined className="cursor-pointer " />
                                </div>
                                <Slider ref={sliderRef} {...settings}>
                                    {files?.length > 0 && files.map((item, index) => (
                                        <div key={index}>
                                            {['jpg', 'jpeg', 'png', 'gif', 'JPEG'].includes(item?.name?.split('.').pop().toString()) ?
                                                <img alt="" src={item.location} width={610} height={354} style={{ borderRadius: '5px', width: '100%' }} /> :

                                                <iframe title="PDF Viewer" src={`https://docs.google.com/viewer?url=${encodeURIComponent(item.location)}&embedded=true`} width={610} height={354} style={{ borderRadius: '5px', width: '100%' }} frameBorder="0" scrolling="no" />
                                            }

                                        </div>
                                    ))}
                                </Slider>
                                <div className="slick-arrow custom-next" onClick={goToNextSlide}>
                                    <ChevronRightOutlined style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                        ): <strong style={{ textAlign: 'center' }}>No Food Menu Card Available</strong>}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
