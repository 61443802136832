import React from 'react';
import { Container, Grid, Link, Box } from '@mui/material';
import Logo from "../../assets/images/logo.png";

const Footer = () => {
    return(
        <div className='main_footer'>
            <Container>
                <Grid container spacing={4} className='footer_upper_content align-items-center'>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='main_logo'>
                            <img src={Logo} alt="logo" />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} style={{ paddingLeft: '65px'}}>
                        <div className='footer_contact_links'>
                            <nav>
                                <Link href="/about-us">
                                    About Us
                                </Link>
                                <Link href="/about-us">
                                    Contact Us
                                </Link>
                                <Link href="/">
                                    Join Us
                                </Link>
                            </nav>  
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div className='footer_contact'>
                        <div className='contact_item'>Phone/Whatsapp: <span>9050008000</span></div>
                        <div className='contact_item'>Email: <span>dstayz@gmail.com</span></div>
                    </div>
                    </Grid>
                </Grid>
                <Box mt={3} className='footer_below_content text-center'>
                    <nav>
                        <Link href="/">
                            Privacy Policies
                        </Link>
                        <Link href="/">
                            Terms & Conditions
                        </Link>
                    </nav>  
                </Box>
            </Container>
        </div>
    )
}

export default Footer;



// const Footer = () => {
//   return (
    
//   );
// };

// export default Footer;
