import React from "react";
import BedMatrixBhk from "./bedMatrixBhk";
import { PROPERTY_TYPES } from "../../common/constant";
import BedMatrixHome from "./bedMatrixHome";

function BedMatrixDetails({ propertyDetails, basicDetails }) {
    return (
        <div className="bedMatrixPg">

            {/* Render Bed matrix Bhk  */}
            {(propertyDetails?.type === PROPERTY_TYPES.APARTMENT ?
                <BedMatrixBhk propertyDetails={propertyDetails} basicDetails={basicDetails} /> :
                <BedMatrixHome propertyDetails={propertyDetails} basicDetails={basicDetails} />
            )}
        </div>
    );
}

export default BedMatrixDetails;