import React from 'react';
import { Container, Grid, Link, Box } from '@mui/material';
import Logo from "../../assets/images/logo.png";
import SmallLogo from "../../assets/images/logo-sm.png";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Button from '@mui/material/Button';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const handleClickToProperty = () => {
        navigate("/enlist-your-property")
    }
    const backToHome = () => {
        navigate("/")
    }
    return(
        <div className='header'>
            <Container>
                <div className='header_main d-flex justify-content-between align-items-center'>
                    <div className='main_logo'>
                        <img src={ isSmallScreen ? SmallLogo : Logo} alt="logo" onClick={backToHome} />
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='enlist_prop' onClick={handleClickToProperty}>
                            <HomeOutlinedIcon /> Enlist your Property
                        </div>
                        <Button className='theme_button sign_in_button' variant="contained"><AccountCircleOutlinedIcon className='me-2 '/> <span>Sign In</span></Button>
                    </div>
                </div>
            </Container>  
        </div>
    )
}

export default Header;