import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'transparent',
                color: '#333',
                left: '-15px',
                zIndex: 1,
                padding: '10px',
                top: '35%',
                transform: 'translate(-35%, -35%)',
            }}
            onClick={onClick}
        >
            <NavigateBeforeOutlinedIcon />
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'transparent',
                color: '#333',
                right: '15px',
                zIndex: 1,
                padding: '10px',
                top: '35%',
                transform: 'translate(-35%, -35%)',
            }}
            onClick={onClick}
        >
            <NavigateNextOutlinedIcon />
        </div>
    );
};
const CommonSlider = ({ images }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ]
    };
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 767);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 767);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="slider-container property_img">

            {((!isSmallScreen && images?.length < 5) || (isSmallScreen && images?.length === 1)) ?
                (<div className="grid-container" style={{ display: 'grid', gridTemplateColumns: `repeat(${images?.length}, 1fr)`, gap: '15px' }}>
                    {images?.map((image, index) => (
                        <div className="grid-item" key={index}>
                            <img src={image.location} alt={image.name} style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '6px' }} />
                        </div>
                    ))}
                </div>)
                :
                (
                    <Slider {...settings}>
                        {images?.map((image, index) => (
                            <div key={index}>
                                <img src={image.location} alt={image.name} style={{ width: '100%', height: '200px' }} />
                            </div>
                        ))}
                    </Slider>
                )
            }
        </div>
    )
}
export default CommonSlider;