import React, { useState } from "react";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Modal, TextField, Box } from '@mui/material';
import { Button } from 'reactstrap';
import ConfirmationModal from "../Common/ConfirmationModal";
import { ACTIONS } from "../../common/constant";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { AsterikLabel } from "../Common/AsterikLabel";
import { sendEmailForInterestedTenant } from "../../api/property";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '10px',
    '@media screen and (max-width: 768px)': {
        width: 'calc(100% - 20px)',
        maxWidth: 500,
    }
};

export function InterestedTenantModal({ isOpen, handleClose, bedDetails }) {
    const [isConfirm, setIsConfirm] = useState(false);
    const [modal, setModal] = useState(true);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            contact_number: '',
        },
        validateOnMount: true,
        validationSchema: Yup.object({
            name: Yup.string().strict(true).required('Please enter your name'),
            contact_number: Yup.string()
                .strict(true)
                .trim('Must not include leading and trailing spaces')
                .matches(/^[0-9]{10}$/, 'Contact number must be 10 digits')
                .required('Please enter your contact number'),
        }),
    });

    const onClose = () => {
        setModal(false)
    }

    const addInterestedTenantForBed = async () => {
        try {
            const payload = {
                name: formik.values.name,
                contact_number: formik.values.contact_number,
                ...bedDetails
            }

            const response = await sendEmailForInterestedTenant(payload)
            if (response.status === 200) {
                toast.success('Send Email to admin successfully');
            } else {
                toast.error('Send Email to admin failed');
            }
        } catch (err) {
            toast.error(err.response.data.message);
        } finally {
            setIsConfirm(false);
            handleClose();
        }
    }

    return (
        <div>
            {modal && (
                <Modal
                    open={isOpen}
                    disableScrollLock={true}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"

                >
                    <Box sx={style} className="tenantRemove">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Please fill up this form to get a call from the admin </h5>
                            <CloseOutlinedIcon onClick={handleClose} className="cursor-pointer" />
                        </div>
                        <div className="tenantBody">
                            <div className="mb-4">
                                <TextField
                                    className='form-control'
                                    size='small'
                                    type='text'
                                    label={<div>Name <AsterikLabel /></div>}
                                    name='name'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik?.values?.name}
                                />
                                <div>
                                    {formik.touched?.name && formik.errors?.name && (
                                        <span className="text-danger">{formik.errors?.name}</span>
                                    )}
                                </div>
                            </div>
                            <div className="mb-4">
                                <TextField
                                    className='form-control'
                                    size='small'
                                    type='text'
                                    label={<div>Contact No <AsterikLabel /></div>}
                                    name='contact_number'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik?.values?.contact_number}
                                />
                                <div>
                                    {formik.touched?.contact_number && formik.errors?.contact_number && (
                                        <span className="text-danger">{formik.errors?.contact_number}</span>
                                    )}
                                </div>
                            </div>

                        </div>

                        <div className="modal_footer text-center">
                            <Button className="yellow_gradient_btn"
                                disabled={!formik.isValid}
                                onClick={() => {
                                    onClose()
                                    setIsConfirm(true)
                                }}
                            >
                                Submit
                            </Button>
                        </div>
                    </Box>
                </Modal>
            )}
            <ConfirmationModal
                action={ACTIONS.CREATE}
                show={isConfirm}
                onCloseClick={() => setIsConfirm(false)}
                onAcceptClick={addInterestedTenantForBed}
            />
        </div>
    )
}