import React from "react";
import EnlistProperty from "../../assets/images/enlist-property.png";

export const EnlistRegistration = () => {
    return (
        <div className="img_section">
            <img src={EnlistProperty} alt={EnlistProperty} />
            <h4>Registration Tips</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam eget condimentum metus. Maecenas id nulla ante. Nullam pulvinar at mi id pulvinar. Mauris nec auctor libero. Aenean finibus luctus elit maximus faucibus. Pellentesque commodo augue vel arcu sodales, vitae vestibulum est fermentum. Nulla non luctus velit. Phasellus eu varius augue, in consectetur nisl.</p>
        </div>

    )
}