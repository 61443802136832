import React from 'react';
import Header from "../Common/header";
import Footer from "../Common/footer";

const MainLayout = (props) => {
  // console.log("Props", props)
  return (
    <div className="main-layout">
      <header>
        <Header />
      </header>
      <main>
        {props.children}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default MainLayout;
