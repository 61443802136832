import React, { useState, useEffect } from 'react';
import Slider from "../../components/Common/Slider"
import Container from '@mui/material/Container';
import BedMetrix from '../../components/Property/bedMatrix'
import Button from '@mui/material/Button';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PropertyDescription from '../../components/Property/propertyDescription';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchProperty } from '../../api/property';
import { CircularProgress } from '@mui/material';
import { HouseRuleModal } from '../../components/Property/houseRuleModal';

export const PropertyDetail = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isLoading, setIsLoading] = useState(false)
    const [propertyDetails, setPropertyDetails] = useState();
    const [basicPropertyData, setBasicPropertyData] = useState();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location?.state?.propertyId) {
            const property = JSON.parse(location?.state?.propertyId)
            fetchPropertyDetails(property?.id)
            setBasicPropertyData(property);
        }
    }, [location?.state?.propertyId])

    const fetchPropertyDetails = async (id) => {
        try {
            setIsLoading(true)
            const response = (await fetchProperty(id, { type: 'website' })).data.data;
            setPropertyDetails(response);
        } catch (err) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    const proceedToSchedule = (property) => {
        return navigate("/schedule-form", {
            state: { property, }
        })
    }

    return (
        <div className='detail_pg'>
            {isLoading && !propertyDetails && (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            {propertyDetails && (
                <section>
                    <Container>
                        <Slider images={basicPropertyData?.pictures} />
                        <section className='available_room_detail'>
                            <PropertyDescription propertyDetails={basicPropertyData} />
                            <div className='bed_matrix_block'>
                                <div className='bed_matrix_header d-flex align-items-center justify-content-between mb-2'>
                                    <div className='page_header'>
                                        <h2>Bed Arrangements</h2>
                                    </div>
                                    <div className="schedule_btns">
                                        {propertyDetails?.rules?.length > 0 && (
                                            <Button className='theme_button sign_in_button mx-2' variant="contained" style={{ zIndex: 0 }} onClick={handleOpen}><RemoveRedEyeOutlinedIcon className='me-2' />House Rule</Button>
                                        )}
                                        <Button className="property_common_btn red_bordered_btn" onClick={() => proceedToSchedule(basicPropertyData)}><CalendarMonthOutlinedIcon /> <span>Schedule Visit</span></Button>
                                    </div>
                                </div>
                                <BedMetrix propertyDetails={propertyDetails} basicDetails={{ ...basicPropertyData, rules: propertyDetails.rules, global_data: propertyDetails?.global_data }} />
                            </div>
                        </section>
                    </Container>
                </section>
            )}

            {open && (
                <HouseRuleModal open={open} handleClose={handleClose} rules={propertyDetails?.rules} />
            )}
        </div>
    )
}