import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Card, Stack, Button } from '@mui/material';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import LocalHotelOutlinedIcon from '@mui/icons-material/LocalHotelOutlined';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import StarIcon from '@mui/icons-material/Star';
import AddressComponent from "../../components/Common/Address";
import { getS3BaseUrl } from "../../helpers/string_helper";
import { PropertySlider } from "../../components/Property/PropertyPictureSlider";
import { fetchBedType, fetchRoomType, selectAmenitiesIcon } from "../../components/Common/amenities_icon";
import { amber } from '@mui/material/colors';
import { FoodMenuDocumentSlider } from "../../components/Property/foodMenuSlider";
import SuitableTags from '../../components/Common/suitableTags';
import Amenities from "../../components/Common/amenitiesLanding";

const PropertyListing = ({ propertyData, filterData }) => {
    const navigate = useNavigate();
    const [isFoodMenu, setIsFoodMenu] = useState(false);
    const [foodMenuFiles, setFoodMenuFiles] = useState();
    const [propertyList, setPropertyList] = useState([]);
    const s3BaseUrl = getS3BaseUrl()

    const toggleFoodMenu = () => {
        setIsFoodMenu(!isFoodMenu)
    }

    useEffect(() => {
        if (propertyData) {
            const result = propertyData?.map((item) => {
                return {
                    ...item,
                    bedType: item.roomBedType.map((room) => {
                        return `${fetchRoomType(room.room_type_id)} ${fetchBedType(room.bed_count)}`
                    }),
                    property: {
                        ...item.property,
                        pictures: item.property?.pictures?.length ? item.property.pictures?.map((pic) => {
                            return { ...pic, location: `${s3BaseUrl}${pic?.location}` }
                        }) : [],
                        food_menu_documents: item.property?.food_menu_documents?.length ? item.property.food_menu_documents?.map((menu) => {
                            return { ...menu, location: `${s3BaseUrl}${menu?.location}` }
                        }) : [],
                        amenities: Array.isArray(item.property?.amenities) ? item.property.amenities : [],

                    }

                }
            })
            setPropertyList(result)
        }
    }, [propertyData]);

    useEffect(() => {
        if (filterData && propertyList?.length > 0) {
            handleFilter(filterData)
        }
    }, [filterData, propertyList])

    const handleFilter = (data) => {
        const priceRangeArray = data?.minBedPriceRange?.split('-');
        const filteredData = propertyList?.filter((el) => {
            if ((data.bedType ? el.bedType.includes(data.bedType) : true) && (data?.minBedPriceRange ? (parseInt(priceRangeArray[0]) <= parseInt(el.minBedPrice) && parseInt(priceRangeArray[1]) >= parseInt(el.minBedPrice)) : true)) {
                if (data?.near_to?.length > 0) {
                    const nearToArray = el.property?.near_to?.split(',')
                    const isNearTo = nearToArray.filter(item => data.near_to.includes(item));
                    return isNearTo?.length > 0 ? el : ''
                } else {
                    return el;
                }
            }
        })
        setPropertyList(filteredData)
    }

    const avarageRating = (ratings) => {
        return ratings.reduce((acc, curr) => acc + curr.rating, 0) / ratings.length;
    }

    const handlePropertyClick = (property) => {
        navigate('/property-detail', {
            state: {
                propertyId: JSON.stringify(property)
            }
        });
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleHiddenList = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const proceedToSchedule = (property) => {
        return navigate("/schedule-form", {
            state: { property, }
        })    
    }

    return (
        <div className="property_listing">
            {propertyList?.length > 0 && propertyList.map((item, index) => (
                <Card className="property_card" key={index}>
                    <div className="single_property_card">
                        <div className="slider-container property_img">
                            <PropertySlider pictures={item.property?.pictures} />
                        </div>
                        <div className="content-container">
                            <div className="property_header d-flex justify-content-between align-items-start">
                                <div>
                                    <h2 onClick={() => handlePropertyClick(item.property)} style={{ cursor: 'pointer' }}>{item.property?.name}</h2>
                                    {item.property.property_rating?.length > 0 ? (
                                        <Stack direction='row' alignItems="center" spacing={0.5} className='d-flex'>
                                            <div> {avarageRating(item.property.property_rating)} </div>
                                            <StarIcon sx={{ color: amber[500] }} />
                                            <div>{`Based on ${item.property.property_rating?.length} ratings`}</div>
                                        </Stack>
                                    )
                                        : (<Stack direction='row' alignItems="center" spacing={0.5} className='d-flex my-2'>
                                            No rating found
                                        </Stack>)}
                                </div>
                                <div className="header_btns d-flex justify-content-between">
                                    <Button className='theme_button sign_in_button' variant="contained" style={{ zIndex: 0 }} onClick={() => {
                                        setFoodMenuFiles(item.property?.food_menu_documents)
                                        toggleFoodMenu()
                                    }} ><RestaurantOutlinedIcon className='me-2' />Food Menu</Button>
                                    <div className="ribbons-wrapper">
                                        <span className="ribbon"> <LocalHotelOutlinedIcon />&nbsp; <span>{item?.vacantBedCount}</span>&nbsp;Beds Available</span>
                                    </div>
                                </div>
                            </div>
                            <div className="property_content">
                                <div className="main_location d-flex">
                                    <RoomOutlinedIcon className="main_icon" />
                                    <p className="body_font_md"><AddressComponent address={item.property.address} /> <a href={`https://www.google.com/maps/dir/?api=1&destination=${item?.property?.latitude},${item?.property?.longitude}&dir_action=navigate`} target="_blank" rel="noreferrer">View in Map</a></p>
                                </div>
                                <div className="tags_block align-items-start justify-content-between" style={{
                                    display: item.property?.near_to ? 'flex' : 'none'
                                }}>
                                    <div className="tag_heading"><MyLocationOutlinedIcon className="main_icon theme_color" /> <span className="body_font_md font_bold font_black">Suitable For</span></div>
                                    <div style={{ width: '88%' }}>
                                        <SuitableTags tags={item?.property?.near_to?.split(',')} onClick={handleHiddenList} />
                                    </div>
                                </div>
                                {/* <div className="amenities" style={{ display: item.property?.amenities?.length ? 'flex' : 'none' }}>
                                    <ul className="list-inline d-flex align-items-center">
                                        {item.property?.amenities?.length > 0 && item.property?.amenities.map((am, amIndex) => (
                                            <li className="body_font_md" key={amIndex}> {selectAmenitiesIcon(am?.toLowerCase())} {am}</li>
                                        ))}
                                    </ul> */}
                                <div className="amenities" style={{ display: item.property?.amenities?.length ? 'flex' : 'none'}}>
                                    <Amenities tags={item.property?.amenities?.map(am => ({ amenity: am, icon: selectAmenitiesIcon(am?.toLowerCase()) }))} onClick={handleHiddenList} />

                                </div>
                                <div className="bed_type d-flex align-items-start">
                                    <div className="bed_type_header">
                                        <HotelOutlinedIcon className="theme_color" /> <span style={{ width: '90px' }} className="body_font_md font_bold font_black me-2 mb-2">Bed Type:</span>
                                    </div>
                                    <ul className="list-inline d-flex mb-0">
                                        {item.roomBedType?.length > 0 && item.roomBedType.map((room, roomIndex) => (
                                            <li className={room.room_type_id === 1 ? "tag_block primary_block" : 'tag_block default_block'}>
                                                {item.bedType[roomIndex]}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="amount_block d-flex justify-content-between align-items-center">
                                    <div className="amt d-flex align-items-center"><ShoppingBasketOutlinedIcon className="main_icon theme_color" /> <span className="theme_color">₹{item.minBedPrice}/</span> month onwards</div>
                                    <div className="schedule_btns">
                                        <Button className="property_common_btn red_bordered_btn" onClick={()=>proceedToSchedule(item?.property)}><CalendarMonthOutlinedIcon /> <span>Schedule Visit</span></Button>
                                        <Button className="property_common_btn yellow_btn" onClick={() => handlePropertyClick(item?.property)}><RemoveRedEyeOutlinedIcon /><span>View Details</span></Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </Card>
            ))}

            {isFoodMenu && <FoodMenuDocumentSlider isOpen={isFoodMenu} handleClose={toggleFoodMenu} files={foodMenuFiles} />}
        </div>
    );
}

export default PropertyListing;
