import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// // Calendar
// import calendar from './calendar/reducer';

// // E-commerce
// import ecommerce from './e-commerce/reducer';

// // chat
// import chat from './chat/reducer';

// // invoices
// import invoices from './invoices/reducer';

// // contacts
// import contacts from './contacts/reducer';

const rootReducer = combineReducers({
  // public
  Layout,
});

export default rootReducer;
