import React from "react";
import { Container } from '@mui/material';
import AboutImage from "../../assets/images/about.png";
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
// import Whatsapp from "../../assets"

export default function AboutUs(){
    return (
        <div className='manage_enlist_property'>
            <section>
                <Container>
                    <div className="cms_page about">
                        <div className="d-flex cms_detail_section">
                            <img src={AboutImage} alt={AboutImage} />
                            <div className="cms_common_pg">
                                <div className="cms_page_header">
                                    <h2>About Us</h2>
                                </div>
                                <div className="cms_page_body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed egestas nisi tellus, et venenatis est blandit quis. Cras gravida eros enim. Morbi pellentesque nisl eget sem finibus dictum. Morbi vestibulum augue quis odio sagittis, id semper ipsum vestibulum. In ac ullamcorper elit. Integer sagittis pharetra erat, eu fermentum urna mattis et.</p>
                                    <ul>
                                        <li>In a sapien venenatis, tristique nunc eu, fermentum dui.</li>
                                        <li>In a sapien venenatis, tristique nunc eu, fermentum dui.</li>
                                        <li>In a sapien venenatis, tristique nunc eu, fermentum dui.</li>
                                        <li>In a sapien venenatis, tristique nunc eu, fermentum dui.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="contact_block">
                            <h2>Contact Us</h2>
                            <ul className="list-inline">
                                <li><FmdGoodOutlinedIcon /> HFHC+FP3, Biswa Bangla Sarani, Major Arterial Road(South-East), 
                                Action Area I, Narkel Bagan, Newtown, West Bengal 700156</li>
                                <li><LocalPhoneOutlinedIcon />9050008000</li>
                                <li><MailOutlinedIcon />dstayz@gmail.com</li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
} 
