import React, { useState, useRef } from "react";
import Slider from 'react-slick';
import Appartment from "../../assets/images/appartment.png";

export const PropertySlider = ({ pictures }) => {
    // Slider setting
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    let sliderRef1 = useRef(null);
    let sliderRef2 = useRef(null);

    return (
        <div className="pro_slider">
            {pictures.length === 1 ? (
            <img src={pictures[0].location} alt={pictures[0].name} style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '3px' }} />
            ) : (
            <>
                <Slider asNavFor={nav2} ref={slider => (sliderRef1 = slider)} arrows={false} autoplay={true} autoplaySpeed={3000}>
                    {pictures?.map((image, index) => (
                        <div key={index}>
                            <img src={image.location} alt={`slide-${index}`} style={{ width: '100%', height: '232px', background: '#ddd', objectFit: 'cover' }} />
                        </div>
                    ))}
                </Slider>
                <Slider asNavFor={nav1} ref={slider => (sliderRef2 = slider)} slidesToShow={4} swipeToSlide={false}
                    focusOnSelect={true} arrows={false}>
                    {pictures?.map((image, index) => (
                        <div key={index}>
                            <img src={image?.location} alt={`slide-${index}`} style={{ width: '85px', height: '60px', objectFit: 'cover' }} />
                        </div>
                    ))}
                </Slider>
            </>
            )
        }
        </div>
    )
}