import React, { useState, useEffect, useRef } from "react";
import Popover from '@mui/material/Popover';
import { convertToMeridiem } from "@mui/x-date-pickers/internals/utils/time-utils";
import { ContactMailSharp } from "@mui/icons-material";

const Amenities = ({ tags, onClick }) => {
    const [visibleTags, setVisibleTags] = useState([]);
    const [hiddenTags, setHiddenTags] = useState([]);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const tagContainerRef = useRef(null);

    // Function to update the number of visible tags based on container width and screen width
    const updateVisibleTags = () => {
        if (tagContainerRef.current) {
            const containerWidth = tagContainerRef.current.offsetWidth;
            const tagElements = tagContainerRef.current.querySelectorAll('li');
            let totalWidth = 0;
            let tagsToShow = [];
            let tagsToHide = [];
            const maxVisibleTags = screenWidth <= 767 ? (screenWidth <= 480 ? (screenWidth >= 384 ? 3 : 2) : (screenWidth <= 580 ? 3 : 5)) : (screenWidth <= 901 ? 3 : 4); // Set max visible tags based on screen width

            tagElements.forEach((tag, index) => {
                totalWidth += tag.offsetWidth;
                if (totalWidth <= containerWidth && tagsToShow.length < maxVisibleTags) {
                    tagsToShow.push(tags[index]);
                } else {
                    tagsToHide.push(tags[index]);
                }
            });
            // If fewer than maxVisibleTags are visible, show all tags
            if (tagsToShow.length < maxVisibleTags) {
                setVisibleTags(tags);
                setHiddenTags([]);
            } else {
                setVisibleTags(tagsToShow);
                setHiddenTags(tags.slice(tagsToShow.length));
            }
        }
    };

    useEffect(() => {
        // Update screenWidth on resize
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        // Ensure updateVisibleTags runs after initial render
        const timer = setTimeout(() => {
            updateVisibleTags();
        }, 0);

        // Setup ResizeObserver to handle container resize events
        const resizeObserver = new ResizeObserver(() => {
            updateVisibleTags();
        });

        if (tagContainerRef.current) {
            resizeObserver.observe(tagContainerRef.current);
        }

        // Cleanup observers and event listeners on component unmount
        return () => {
            clearTimeout(timer);
            window.removeEventListener('resize', handleResize);
            if (tagContainerRef.current) {
                resizeObserver.unobserve(tagContainerRef.current);
            }
        };
    }, [tags, screenWidth]); // Include screenWidth in the dependency array

    const [anchorEl, setAnchorEl] = useState(null);

    const handleHiddenList = (event) => {
        setAnchorEl(event.currentTarget);
        if (onClick) onClick(event); // Call parent handler if provided
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div className={`tags_container ${visibleTags.length <= (screenWidth <= 767 ? (screenWidth <= 480 ? 3 : (screenWidth <= 580 ? 3 : 5)) : (screenWidth <= 901 ? 3 : 4)) ? 'tags_container_less_than_max' : ''}`} ref={tagContainerRef}>
                <ul className="list-inline d-flex ms-2 mb-0">
                    {visibleTags.map((tag, index) => (
                        <li className="list-inline-item d-flex align-items-center" key={index}>
                            {tag.icon}
                            <span className="ms-2">{tag.amenity}</span>
                        </li>
                    ))}
                    {hiddenTags.length > 0 && (
                        <li className='more_tags cursor_pointer' onClick={handleHiddenList}>
                            + {hiddenTags.length}
                        </li>
                    )}
                </ul>
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <ul className="hidden_tag_list ms-0 mb-0">
                    {hiddenTags.map((tag, index) => (
                        <li className="list-inline-item d-flex align-items-center" key={index}>
                            {tag.icon}
                            <span className="ms-2">{tag.amenity}</span>
                        </li>
                    ))}
                </ul>
            </Popover>
        </>
    );
};

export default Amenities;
