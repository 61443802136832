import { Box, Modal } from "@mui/material"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

 const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 500,
        width: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: '10px',
    };

export const HouseRuleModal = ({ open, handleClose, rules}) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className='house_rules'
            >
                <Box sx={style}>
                    <h2 id="modal-modal-title">
                        House Rules
                        </h2>
                         <div className="d-flex justify-content-between align-items-center">
                            <div className="crossIcon cursor-pointer position-absolute">
                                <CloseOutlinedIcon onClick={handleClose} />
                            </div>
                        </div>
                        <ul>
                            {rules?.length && rules.map((rl, rlIndex) => (
                                <li key={rlIndex}>{ rl}</li>
                            )) }
                       
                    </ul>
                </Box>
            </Modal>
        </div>
    )
}