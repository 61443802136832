import React, { useState, useEffect } from "react";
import FloorBedMatrix from "./floorBedMatrix";

function BedMatrixHome({ propertyDetails, basicDetails }) {
    const [floorRowData, setFloorRowData] = useState([]);

    useEffect(() => {
        if (propertyDetails) {
            const floorData = propertyDetails?.floors?.map((floor) => {
                let sortedRoom = []
                if (floor?.rooms?.length > 0) {
                    sortedRoom = floor?.rooms.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
                }
                if (sortedRoom?.length > 0) {
                    return {
                        name: floor.name,
                        rooms: sortedRoom,
                        ...(floor?.user !== null ? {
                            user: floor.user
                        } : {
                            user: propertyDetails.user
                        })
                    }
                }
            })

            let sortedFloor = []
            if (floorData?.length > 0) {
                sortedFloor = floorData.toSorted((a, b) => a.name > b.name ? 1 : -1).map((it) => it);
            }
            setFloorRowData(sortedFloor)
        }
    }, [propertyDetails])

    return (
        <div className="whole_bedmatrix">
            <div className="bm_body_cols">
                {floorRowData.map((item, index) => (
                    <div className="bm_single_row d-flex w-100" key={index}>
                        <div className="bm__flat">
                            <FloorBedMatrix floor={item?.name} rooms={item?.rooms} basicDetails={basicDetails} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BedMatrixHome;
