import React, { useState } from 'react';
import { Container, Card, TextField, Grid, InputLabel } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import { FormControlLabel } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const FileButton = styled(Button)({

    backgroundColor: '#ECECEC',
    alignItems: 'center',
    color: '#565656',
    border: "1px solid #B1B1B1",
    '&:hover': {
        backgroundColor: '#ECECEC',
        color: '#565656',
        border: '1px solid #B1B1B1',
    },
});
const tenantType = [
    {
      value: 'Student',
      label: 'Student',
    },
    {
      value: 'Professional',
      label: 'Professional',
    },
    {
      value: 'Doctor',
      label: 'Doctor',
    },
  ];

export const BookForm = () => {
    const [fileName, setFileName] = useState('No file chosen');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file ? file.name : 'No file chosen');
    };

    return (
        <div className='detail_booking_form'>
            <section className="available_rooms_listing">
                <Container>
                    <div className='form_details mb-3'>
                        <h4>Please fill up the form to proceed with the booking</h4>
                        <Card>    
                            <Grid container spacing={2}>
                                <Grid item md={4} sm={12} className='date_picker_field'>
                                    <InputLabel htmlFor="entry-date">1st Date of Entry</InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker placeholder="Select Date" slotProps={{ textField: { size: 'small', fullWidth:'true' } }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                <InputLabel htmlFor="name">Name</InputLabel>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Name"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <InputLabel htmlFor="email">Email</InputLabel>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Email"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <InputLabel htmlFor="phone">Phone</InputLabel>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Phone"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item md={8} sm={12}>
                                    <InputLabel htmlFor="address">Address</InputLabel>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        placeholder="Address"
                                        fullWidth
                                        size='small'
                                    />
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <InputLabel htmlFor="tenantType">Tenant Type</InputLabel>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        defaultValue="Select"
                                        size='small'
                                        fullWidth
                                    >
                                        {tenantType.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item md={4} sm={12}>
                                    <InputLabel htmlFor="adhar">Upload Aadhar Card</InputLabel>
                                    <FileButton
                                        component="label"
                                        variant="contained"
                                        role={undefined}
                                    >
                                        Choose file
                                        <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                                    </FileButton>
                                    <span style={{ marginLeft: 16 }}>{fileName}</span>
                                </Grid>
                            </Grid>
                            <div className="payment_btn payment_area book_payment_btn my-3 text-center">
                                <Button style={{ width: '238px'}} className='theme_button sign_in_button' variant="contained" >Book Now</Button>
                            </div>
                        </Card>
                    </div>
                </Container>
            </section>
        </div>
    );
};
