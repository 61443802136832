import React from "react";
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
  } from "@react-pdf/renderer";
import moment from "moment";
import logo from '../../assets/images/logo1.png'

  const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
    },
    headerContainer: {
        marginVertical: 10,
        flexDirection: 'row',
        // justifyContent: 'space-between',
        borderBottom: 1
    },
    container: {
        marginVertical: 5,
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    headerTitle: {
        fontSize: 24,
        textTransform: 'uppercase',
    },
    title: {
        fontSize: 10,
        marginHorizontal: 5,
      },
    dataValue: {
        marginLeft: 5,
        fontSize: 10,
    },
  });

  
const SchedulePropertyVisitSlipPdf = (props) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={{
                    margin: 10,
                    height: '98%',
                    border: 1,
                }}>
                    <View style={styles.headerContainer}>
                        <View style={{marginLeft: 10}}>
                            <Image src={logo} style={{ height: 20, width: 50 }} />
                        </View>
                        <View style={{ marginLeft: 100 }}>
                            <Text style={styles.headerTitle}>Property Visit Slip ee</Text>
                        </View>
                    </View>
                    <View style={{
                        marginHorizontal: 'auto',
                        paddingHorizontal: 10,
                        border: 1
                    }}>
                        <View style={styles.container}>
                            <Text style={styles.title}>Visitor Name:</Text>
                            <Text style={styles.dataValue}>{props?.item?.fullname}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Visitor Contact Number:</Text>
                            <Text style={styles.dataValue}>{props?.item?.phone_no}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Property Name:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.name}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Caretaker Name:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.care_taker_name}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Caretaker Contact Number:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.care_taker_contact_no}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Property Manger Name:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.property_manager?.name}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Property Manger Contact Number:</Text>
                            <Text style={styles.dataValue}>{props?.item?.property?.property_manager?.contact_number}</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Visit  Date and Time: </Text>
                            <Text style={styles.dataValue}>{moment(props?.item?.visit_datetime).format('LLL') }</Text>
                        </View>
                        <View style={styles.container}>
                            <Text style={styles.title}>Note: </Text>
                            <Text style={styles.dataValue}>{props?.item?.note?.description}</Text>
                        </View>
                    </View>
                    {/* <View style={styles.headerContainer}>
                    </View> */}
                </View>
            </Page>
    </Document>
    )
};
export default SchedulePropertyVisitSlipPdf;